import React from "react";

export default function TagSectionWrapper({ children, name, variant }) {
  const tagClassname = `text-${variant ?? "2xl"} tracking-wide font-spacemono drop-shadow-lg font-semibold`;

  return (
    <section className="text-lg flex flex-col gap-3 text-justify self-center antialiased font-medium font-caskaydiacove w-full">
      <h3 className={tagClassname}>&lt;{name}&gt;</h3>
      <div className="text-sm md:text-lg px-2 tracking-tight">{children}</div>
      <h3 className={tagClassname}>&lt;/{name}&gt;</h3>
    </section>
  );
}
