import Typewriter from "typewriter-effect";
import "./App.css";

import github from "./assets/github-icon.svg";
import linkedin from "./assets/linkedin-icon.svg";

import bash from "./assets/bash-icon.svg";
import docker from "./assets/docker-4.svg";
import git from "./assets/git-icon.svg";
import linux from "./assets/linux-tux-1.svg";
import postman from "./assets/postman.svg";
import vscode from "./assets/vsc.svg";

import { GraduationCapIcon, Mail } from "lucide-react";
import azure from "./assets/azure-2.svg";
import googlecloud from "./assets/google-cloud-1.svg";
import BackendIcons from "./components/BackendIcons";
import ExperienceCard from "./components/ExperienceCard";
import ExperienceCardAlt from "./components/ExperienceCardAlt";
import FrontendIcons from "./components/FrontendIcons";
import TagSectionWrapper from "./components/TagSectionWrapper";
import EducationCardAlt from "./components/EducationCardAlt";

const iconWidth = 40;
const iconHeight = 40;

const socialsList = [
  <a href="https://github.com/jaimemsaramago" target="_blank">
    <img
      src={github}
      alt="github"
      width={25}
      height={25}
      className="cursor-pointer bg-white hover:scale-110 saturate-0 hover:saturate-100 rounded-full ease-in-out duration-150"
    />
  </a>,

  <a href="https://www.linkedin.com/in/jaimemsaramago" target="_blank">
    <img
      src={linkedin}
      alt="linkedin"
      width={25}
      height={25}
      className="cursor-pointer hover:bg-white hover:scale-110 rounded-sm saturate-0 hover:saturate-100  ease-in-out duration-150"
    />
  </a>,

  <a href="mailto:saramagojaime@gmail.com">
    <Mail
      className="saturate-0 stroke-yellow-100 hover:scale-110 hover:saturate-100 ease-in-out duration-150"
      size={25}
    />
  </a>,
];

function App() {
  return (
    <div className="relative">
      <div className="xl:fixed xl:flex xl:flex-col xl:items-center hidden left-[5%] bottom-0 z-10 ">
        <div className="w-1 h-32 border-r-2 border-r-white/70 "></div>
        <div className="flex flex-col items-center gap-y-5 my-4 select-none">
          {socialsList}
        </div>
        <div className="w-1 h-16 border-r-2 border-r-white/70 shadow-xl shadow-white"></div>
      </div>
      <div className="bg-black/40 backdrop-blur-md absolute w-full h-full"></div>

      <main className="flex flex-col gap-y-7 w-full px-10 pt-4 pb-2 drop-shadow-xl bg-opacity-10 rounded-xl xl:w-[60%] mx-auto font-ubuntu">
        <h1 className="text-3xl md:text-4xl font-bold text-center mt-4">
          <Typewriter
            options={{
              strings: ["Jaime Saramago"],
              autoStart: true,
              delay: 100,
              loop: true,
              deleteSpeed: 50,
            }}
          />
        </h1>
        <div className="h-1 w-full border-t-2 rounded-lg border-white/90 shadow-xl"></div>
        <TagSectionWrapper name="ABOUT-ME">
          <div className="font-geist text-lg">
            <p>
              I hold a MSc degree in computer science and engineering and have a
              passion for technology and innovation. I have a solid academic
              background in software development and a keen interest in the
              latest technologies and full-stack development. I am always
              looking for new challenges and opportunities to learn and grow.
            </p>
            <br />
            <p>
              I have worked on a variety of projects, spanning from web
              applications to mobile apps. I am always looking for new ways to
              apply my skills and knowledge to solve real-world problems.
            </p>
          </div>
        </TagSectionWrapper>

        <div className="h-1 w-full border-t-2 rounded-lg border-white/90 shadow-xl"></div>

        <div className=" flex flex-col gap-y-10">
          <TagSectionWrapper name={"EDUCATION"}>
            <div className="grid md:grid-cols-2 gap-5 md:place-items-center">
              <EducationCardAlt
                title="MSc in Computer Science and Engineering"
                company="Nova School of Science and Technology"
                link={"https://www.fct.unl.pt/"}
                start="Sept. 22"
                end="Nov. 24"
                color="#0000ff"
                description={
                  <div className="text-sm mb-4 font-medium drop-shadow-sm list-disc text-justify py-2">
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit. Officiis quidem pariatur consequuntur corporis necessitatibus, ad temporibus praesentium modi a nihil repellat aut reiciendis incidunt. Asperiores, neque? Modi sunt odit quas?
                  </div>
                }
              />
              <EducationCardAlt
                title="BSc in Computer Science and Engineering"
                company="Nova School of Science and Technology"
                link={"https://www.fct.unl.pt/"}
                start="Sept. 19"
                end="Jun. 22"
                color="#0000ff"
                description={
                  <div className="text-sm mb-4 font-medium drop-shadow-sm list-disc text-justify py-2">
                    <p>
                      Completed a wide-ranging course and developed a solid
                      basis in Computer Science, as well as in the fundamental
                      disciplines for working as an Engineer in the modern
                      society.
                    </p>
                    <br />
                    <p>
                      Grasped the fundamentals of object-oriented programming,
                      databases, algorithms and data structures, distributed
                      system architectures and API development.
                    </p>
                  </div>
                }
              />
            </div>
          </TagSectionWrapper>

          <TagSectionWrapper name={"EXPERIENCE"}>
            <div className="grid md:grid-cols-2 gap-5 md:place-items-center">
              <ExperienceCardAlt
                title="Engineer"
                company="Critical Software"
                link="https://www.criticalsoftware.com/"
                start="Dec. 24"
                end="Present"
                color="#d30000"
              />
              <ExperienceCardAlt
                title="Projects Dept. Member"
                company="In-Nova Junior Enterprise"
                link={"https://innova.pt/"}
                start="Jan. 23"
                end="Jun. 24"
                color="#7f0040"
              />
              {/*  <ExperienceCard
                title="Engineer"
                company="CRITICAL SOFTWARE"
                link="https://www.criticalsoftware.com/"
                start="DEC. 24"
                end="pres."
                color="#d30000"
              />
              <ExperienceCard
                title="Projects Dept. Member"
                company="IN-NOVA JUNIOR ENTERPRISE"
                link={"https://innova.pt/"}
                start="JAN. 23"
                end="JUN. 24"
                color="#7f0040"
              /> */}
            </div>
          </TagSectionWrapper>

          <TagSectionWrapper name={"PROJECTS"}>
            <div></div>
          </TagSectionWrapper>

          <TagSectionWrapper name={"TECHNOLOGIES"}>
            <div className="pl-5">
              <p className="font-medium text-sm md:text-lg font-geist">
                These are some of the languages, libraries, frameworks and
                platforms I often use or have knowledge of.
              </p>
              <div className="flex flex-col gap-y-5 my-5 justify-between flex-wrap">
                <TagSectionWrapper name={"FRONTEND"} variant={"xl"}>
                  <FrontendIcons />
                </TagSectionWrapper>

                <TagSectionWrapper name={"BACKEND"} variant={"xl"}>
                  <BackendIcons />
                </TagSectionWrapper>
                <TagSectionWrapper name={"CLOUD"} variant={"xl"}>
                  <div className="flex flex-row items-center gap-5 flex-wrap my-3">
                    <img
                      src={googlecloud}
                      alt="googlecloud logo"
                      width={iconWidth}
                      height={iconHeight}
                    />
                    <img
                      src={azure}
                      alt="azure logo"
                      width={iconWidth}
                      height={iconHeight}
                    />
                  </div>
                </TagSectionWrapper>
                <TagSectionWrapper name={"TOOLS~OS"} variant={"xl"}>
                  <div className="flex flex-row items-center gap-5 flex-wrap my-3">
                    <img
                      src={linux}
                      alt="linux logo"
                      width={iconWidth}
                      height={iconHeight}
                    />
                    <img
                      src={bash}
                      alt="bash logo"
                      width={iconWidth}
                      height={iconHeight}
                      className="animate-pulse"
                    />
                    <img
                      src={docker}
                      alt="docker logo"
                      width={iconWidth}
                      height={iconHeight}
                    />

                    <img
                      src={git}
                      alt="git logo"
                      width={iconWidth}
                      height={iconHeight}
                    />
                    <img
                      src={postman}
                      alt="postman logo"
                      width={iconWidth}
                      height={iconHeight}
                    />
                    <img
                      src={vscode}
                      alt="vscode logo"
                      width={iconWidth}
                      height={iconHeight}
                    />
                    <img
                      src={
                        "https://resources.jetbrains.com/storage/products/company/brand/logos/IntelliJ_IDEA_icon.png"
                      }
                      alt="intellij logo"
                      width={iconWidth}
                      height={iconHeight}
                    />
                  </div>
                </TagSectionWrapper>
              </div>
            </div>
          </TagSectionWrapper>
        </div>

        <div className="h-1 w-full border-t-2 rounded-lg border-white/90 shadow-xl"></div>

        <TagSectionWrapper name={"HOBBIES"}>
          <p className="font-medium text-sm md:text-lg font-geist">
            If I'm not up to anything, chances are I'm either:
          </p>
        </TagSectionWrapper>

        <div className="h-1 w-full border-t-2 rounded-lg border-white/90 shadow-xl"></div>
        <div className="xl:hidden flex flex-row items-center gap-3">
          {socialsList}
        </div>

        <footer className="xl:text-sm text-xs text-center font-bold font-ubuntu">
          <span>&copy; Jaime Saramago 2024</span>
        </footer>
      </main>
    </div>
  );
}

export default App;
