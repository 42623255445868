import React from "react";



import express from "../assets/expressjs.webp";
import go from "../assets/go-8.svg";
import java from "../assets/java-4.svg";
import kotlin from "../assets/kotlin-2.svg";
import nodejs from "../assets/nodejs-3.svg";
import python from "../assets/python-5.svg";
import redis from "../assets/redis.svg";
import ruby from "../assets/ruby.svg";
import spring from "../assets/spring-3.svg";
import supabase from "../assets/supabase-icon.svg";

import firebase from "../assets/firebase-1.svg";
import mongo from "../assets/mongodb-icon-1.svg";
import postgre from "../assets/postgresql.svg";

const iconWidth = 40;
const iconHeight = 40;

export default function BackendIcons() {
  return (
    <div className="flex flex-row items-center gap-5 flex-wrap my-3">
      <img src={java} alt="java logo" width={iconWidth} height={iconHeight} />
      <img
        src={go}
        alt="go logo"
        width={iconWidth}
        height={iconHeight}
        className="animate-pulse opacity-80"
      />
      <img
        src={kotlin}
        alt="kotlin logo"
        width={iconWidth}
        height={iconHeight}
      />
      <img
        src={spring}
        alt="spring logo"
        width={iconWidth}
        height={iconHeight}
        className="bg-white rounded-full"
      />
      <img
        src={nodejs}
        alt="nodejs logo"
        width={iconWidth}
        height={iconHeight}
      />
      <img
        src={express}
        alt="express logo"
        width={iconWidth}
        height={iconHeight}
        className="bg-white p-1 rounded-full"
      />
      <img
        src={python}
        alt="python logo"
        width={iconWidth}
        height={iconHeight}
      />
      <img
        src={ruby}
        alt="ruby logo"
        width={iconWidth}
        height={iconHeight}
        className="animate-pulse opacity-80"
      />

      <img
        src={supabase}
        alt="supabase logo"
        width={iconWidth}
        height={iconHeight}
        className="animate-pulse opacity-80"
      />
      <img
        src={postgre}
        alt="postgre logo"
        width={iconWidth}
        height={iconHeight}
      />
      <img
        src={mongo}
        alt="mongo logo"
        width={iconWidth - 10}
        height={iconHeight - 10}
      />
      <img
        src={firebase}
        alt="firebase logo"
        width={iconWidth}
        height={iconHeight}
      />
      <img src={redis} alt="redis logo" width={iconWidth} height={iconHeight} />
    </div>
  );
}
