import { ScrollShadow } from "@nextui-org/scroll-shadow";
import { CalendarIcon, GraduationCapIcon } from "lucide-react";
import React from "react";

export default function EducationCardAlt({
  title,
  company,
  link,
  start,
  end,
  color,
  description,
  skills,
}) {
  return (
    <div className="font-geist flex flex-col w-full md:max-w-md bg-white text-black rounded-lg shadow-sm border border-black/30">
      <div className="flex flex-row w-full p-4">
        <div>
          <h4 className="text-lg md:text-xl font-bold leading-none drop-shadow-md tracking-tight text-left antialiased ">
            {title}
          </h4>
          <a
            href={link}
            target="_blank"
            className="text-[#71717a] text-xs md:text-sm hover:underline hover:underline-offset-2 decoration-2 decoration-slice-[#FFD700]"
          >
            {company}
          </a>
        </div>
        <div className="ml-auto">
          <GraduationCapIcon size={24} className=" stroke-[#71717a]" />
        </div>
      </div>

      <div className="p-6 pt-0 flex flex-col">
        <div className="flex flex-row items-center mb-2">
          <CalendarIcon size={15} className=" stroke-[#71717a] mr-2" />
          <span className="text-sm text-[#71717a]">
            {start} - {end}
          </span>
        </div>
        <ScrollShadow hideScrollBar className="md:h-[7rem] overflow-auto">
          {description ?? (
            <p className="text-sm mb-4 text-left font-medium">
              Lorem ipsum dolor sit, amet consectetur adipisicing elit. Sapiente
              quidem officia, hic necessitatibus, minima eum qui esse dolores
              sit nulla pariatur quam, optio culpa cupiditate deleniti facilis
              dolorum atque fugiat?
            </p>
          )}
        </ScrollShadow>
      </div>
    </div>
  );
}
