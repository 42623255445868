import React from "react";

import css from "../assets/css-3.svg";
import html from "../assets/html-1.svg";
import js from "../assets/logo-javascript.svg";
import nextjs from "../assets/next-js.svg";
import reactnative from "../assets/react-native-1.svg";
import react from "../assets/react.svg";
import reactquery from "../assets/reactquery.webp";
import redux from "../assets/redux.svg";
import tailwindcss from "../assets/tailwind-css-2.svg";
import ts from "../assets/typescript.svg";

const iconWidth = 40;
const iconHeight = 40;

export default function FrontendIcons() {
  return (
    <div className="flex flex-row gap-5 items-center flex-wrap my-3 select-none">
      <img width={iconWidth} height={iconHeight} src={html} alt="html logo" />
      <img width={iconWidth} height={iconHeight} src={css} alt="css logo" />
      <img width={iconWidth} height={iconHeight} src={js} alt="js logo" />
      <img width={iconWidth} height={iconHeight} src={ts} alt="ts logo" />

      <img
        width={iconWidth}
        height={iconHeight}
        src={tailwindcss}
        alt="tailwindcss logo"
      />

      <img width={iconWidth} height={iconHeight} src={react} alt="react logo" />
      <img
        width={iconWidth + 15}
        height={iconHeight + 15}
        src={reactnative}
        alt="reactnative logo"
      />
      <img
        width={iconWidth}
        height={iconHeight}
        src={nextjs}
        alt="nextjs logo"
      />
      <img width={iconWidth} height={iconHeight} src={redux} alt="redux logo" />
      <img
        width={iconWidth}
        height={iconHeight}
        src={reactquery}
        alt="reactquery logo"
      />
    </div>
  );
}
